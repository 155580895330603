import React from "react"
import { Link } from "gatsby"
import {
  Row,
  Col,
  Container,
  Breadcrumb,
  Button,
  Accordion,
  Card,
} from "react-bootstrap"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import HeroMobile from "./../../images/Pielonefral-mobile.jpg"
import HeroTablet from "./../../images/Pielonefral-tablet.jpg"
import HeroDesktop from "./../../images/Pielonefral-desktop.jpg"
import HeroXL from "./../../images/Pielonefral-desktop-xl.jpg"
import Feature1 from "./../../images/pielonefral-feature.jpg"
import Feature4 from "./../../images/prost-psor-plus-herbal-guarantee.jpg"

const PielonefralPageEn = () => (
  <Layout backButton={true} enSite pageInfo={{ pageName: "PielonefralEn" }}>
    <Seo title="Pielonefral" />
    <Container className="p-0">
      <Row>
        <Col className="d-none d-md-block">
          <Breadcrumb>
            <li className="breadcrumb-item">
              <Link to="/en">HOME</Link>
            </li>
            <Breadcrumb.Item active>PIELONEFRAL</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <picture>
            <source srcSet={HeroXL} media="(min-width: 1200px)" />
            <source srcSet={HeroDesktop} media="(min-width: 992px)" />
            <source srcSet={HeroTablet} media="(min-width: 767px)" />
            <source srcSet={HeroMobile} media="(min-width: 370px)" />
            <img
              srcSet={HeroMobile}
              alt="Pielonefral"
              className="w-100 fluid rounded mb-4"
            />
          </picture>
        </Col>
        <Col xs={6} className="d-none d-lg-block large-heading">
          <h5 className="text-uppercase font-light letter-space-wide ">
            Pielonefral
          </h5>
          <h1 className="display-4 font-light">For kidneys</h1>
        </Col>
      </Row>
      <Row className="mb-5 product-container">
        <Col sm={12} className="d-lg-none">
          <h4 className="text-uppercase mb-3 letter-space-wide">Pielonefral</h4>
          <h1 className="display-5 font-light mb-4 large-heading-mobile">
            For kidneys
          </h1>
        </Col>
        <Col sm={12} md={6} className="mb-4">
          <h5 className="subhead font-light">
            Herbal formula to help the kidneys, bladder and urethra.
          </h5>
        </Col>
        <Col sm={12} md={6}>
          <Col className="mb-4 p-0">
            <div>
              <a
                href="https://bemore.shop/ena/pielonefral-for-kidneys-bubretzi"
                target="blank"
                rel="nofollow"
                className="text-decoration-none"
              >
                <Button
                  variant="primary"
                  className="buy-button mx-auto d-block"
                >
                  Buy online at <strong>BeMore.shop</strong>
                </Button>
              </a>
            </div>
          </Col>
          <Col className="mb-4 p-0">
            <div className="pharma-label mx-auto d-block">
              <p>Available in pharmacies across the country</p>
            </div>
          </Col>
          <Col className="mb-4 p-0">
            <div className="pharma-label mx-auto d-block">
              <ul>
                <li>solvent free</li>
                <li>preservatives free</li>
                <li>gluten free</li>
                <li>sugar free</li>
                <li>GMO-free</li>
              </ul>
            </div>
          </Col>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col>
          <h2 className="text-center">No kidney problems</h2>
        </Col>
      </Row>
      <Row className="product-container mb-5">
        <Col md={6} className="order-md-1">
          <img
            srcSet={Feature1}
            alt="No kidney problems"
            className="w-100 fluid rounded mb-4"
          />
        </Col>
        <Col md={6} className="order-md-0">
          <p>
            Pielonefral is a natural product that helps maintain the normal
            functional state of the kidneys and urinary tract. Supports
            excretory kidney function. Pielonefral helps eliminate bacteria that
            cause urinary tract infections and relieves unpleasant symptoms such
            as pain and burning when urinating.
          </p>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col>
          <h2 className="text-center">Guarantee for quality and safety</h2>
        </Col>
      </Row>
      <Row className="product-container mb-5">
        <Col md={6}>
          <img
            srcSet={Feature4}
            alt="Guarantee for quality and safety"
            className="w-100 fluid rounded mb-4 "
          />
        </Col>
        <Col md={6}>
          <p>
            Standardized plant extracts are obtained from plants or parts
            thereof by extraction of biologically active components. Their
            advantage is that the type of active substances can be controlled
            and maintained in a certain quantity and quality.
          </p>
          <p>
            Taking an extract with a proven effect ensures optimal impact on
            body functions. The plant extracts included in Pielonefral
            complement each other's beneficial effects.
          </p>
        </Col>
      </Row>

      <Row className="mb-2 product-container">
        <Col>
          <h5 className="section-heading">Additional information</h5>
        </Col>
      </Row>
      <Row className="mb-5 product-container">
        <Col>
          <Accordion defaultActiveKey="0" className="acc-style">
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="0"
                className="pointer"
              >
                Contents
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <p>
                    <strong>Bearberry leaves</strong> (Folia Uve ursi) 66,0 mg
                  </p>
                  <p>
                    <strong>Horsetail stalk</strong> (Herba Equiseti arvensis)
                    49,5 mg
                  </p>
                  <p>
                    <strong>Cranberry leaves</strong> (Folia Vitis idaei) 33,0
                    mg
                  </p>
                  <p>
                    <strong>Thyme stalk</strong> (Herba Serpilli) 33,0 mg
                  </p>
                  <p>
                    <strong>Chamomile flower</strong> (Flores Chamomillae) 33,0
                    mg
                  </p>
                  <p>
                    <strong>Pumpkin seeds</strong> (Cucurbita maxima Duch) 33,0
                    mg
                  </p>
                  <p>
                    <strong>Birch leaves</strong> (Folia Betulae albae) 33,0 mg
                  </p>
                  <p>
                    <strong>Willow bark</strong> (Cortex Salicis) 26,4 mg
                  </p>
                  <p>
                    <strong>Piraeus roots</strong> (Rhisoma Graminis Agropuri
                    repens) 23,1 mg
                  </p>
                  <p>Excipients: maltodextrin, magnesium stearate</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="1"
                className="pointer"
              >
                Intake
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <p>2 times daily, 1-2 tablets after meals</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="2"
                className="pointer"
              >
                Contraindications
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  <p>
                    None identified. Do not exceed the recommended daily dose.
                    Do not use the product as a substitute for a varied diet.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="3"
                className="pointer"
              >
                Effects
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="3">
                <Card.Body>
                  <p>
                    Pielonefral is a natural product that helps maintain the
                    normal functional state of the kidneys and urinary tract.
                    Supports excretory kidney function.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default PielonefralPageEn
